/* Variables */

:root {
 --color--white: #ffffff;
 --color--black: #000000;
 --font-family--everett: 'everett', sans-serif;
 --font-family--times: "Times New Roman", Times, serif; 
 /* --font-size--everett: 1.1rem; */
 /* --font-size--times: 1.1rem; */
}

@media (max-width: 768px) {
  :root {
    --font-size--everett: 1rem;
    --font-size--times: 1rem;
  }
}


/* resets */

html, body {
  height: 100%;
  overflow: hidden;
}

* {
  color: white;
}

h1,
h2 {
  color: white;
  text-decoration: none;
}

img {
  width: initial;
  height: initial;
  padding-top: initial;
  left: initial;
}

/* Loader Styling */

/* Section Container */
.issue-03--section {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  background-color: black;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
}

.issue-03--section.active {
  opacity: 1;
  pointer-events: all;
}

.loading--content {
  font-family: var(--font-family--everett); 
  font-size: 3rem;
  opacity: 0.8;
}

.issue-03--section button, .loading button {
  border: none;
  background: none;
  font-family: var(--font-family--everett); 
  font-size: 2rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.issue-03--section button, .loading button:hover {
  opacity: 0.6; 
}

.issue-03--section button:hover {
  opacity: 0.6;
}

.issue-03--section .questionnaire--label {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-transform: uppercase;
  text-align: center;
}


.issue-03--section .video--logo {
  opacity: 0; 
  width: 7rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 5;
}


.issue-03--section .video--logo img {
  width: 100%;
}

.issue-03--section .questionnaire--input {
  border-color: white;
  background: none;
  color: white;
  font-family: var(--font-family--everett); 
  padding: 0.75rem;
  border-style: solid;
  font-size: 1rem;
  border-radius: 1rem;
}

.issue-03--section .questionnaire--input:focus {
  /* no focus styles */
  /* border: none; */
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
}

.issue-03--section .questionnaire--input::placeholder {
  color: white;
  font-family: var(--font-family--everett); 
}

.issue-03--section .questionnaire--submit{
  font-size: 1.5rem;
  margin-top: 1rem;
  font-family: var(--font-family--times);
  transition: opacity 0.3s ease-in-out; 
}

.issue-03--section .questionnaire--submit:hover {
  opacity: 1; 
}

/* Results Page */

.issue-03--results {
  font-size: 1.75rem;
  font-family: 'everett', sans-serif;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  background-color: black;
  transition: opacity 0.3s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
}

.issue-03--results.active {
  opacity: 1;
  pointer-events: all;
}

.issue-03--results .results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.issue-03--results .main-header {
  font-size: 3.5rem;
}

.issue-03--results .questionnaire-question {
  font-size: 2rem;
}

.issue-03--results .questionnaire-answer {
  font-size: 1.75rem;
  opacity: 0.8;
  font-family: var(--font-family--times); 
}


.issue-03-results .exit-link {
  font-size: 1rem;
  color: white;
  font-family: var(--font-family--times); 
}

/* Section Video */
.issue-03--section .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.issue-03--section .video-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

/* TEMPORARY – For Placeholder Images */
.issue-03--section .video img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Section Questionnaire */
.issue-03--section form {
  opacity: 0; 
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.issue-03--section form.active {
  opacity: 1;
  pointer-events: all;
}xw

.issue-03--section form mux-player {
  z-index: -1;
  pointer-events: none;
}

/* Hide All Mux Player Controls */
mux-player {
  pointer-events: none;
  --controls: none;
  --media-object-fit: cover;

  position: absolute;
  inset: 0;
  z-index: 0;
}

mux-player::part(video) {
  pointer-events: none;
}

mux-player::part(gesture-layer) {
  display: none;
}


::cue {
  color: white;
  font-family: ''; 
  font-size: "Times New Roman", Times, serif;
  padding-bottom: 1rem;
}

/* mobile text */
@media (max-width: 768px) {
  .loading--content {
    font-size: 1.5rem;
  }

  .loading--content button {
    font-size: 1.25rem;
  }

}